@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
* {
  font-family: "DM Sans", sans-serif;
  cursor: none !important;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

#home-lct .slider {
  direction: ltr; /* default direction is left to right */
}

#home-lct .slider .slick-slide:nth-child(2) {
  direction: rtl; /* reverse direction for the second slide */
}

#image-carousel .slick-track {
  display: flex !important;
  align-items: center !important;
}

.custom-cursor {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.cursor-dot {
  position: fixed;
  pointer-events: none;
  width: 20px;
  height: 20px;
  background-color: #464af8;
  opacity: 0.4;
  border-radius: 50%;
  z-index: 9999;
  transition: width 0.15s ease-out, height 0.15s ease-out,
    opacity 0.15s ease-out;
  transform: translate(-50%, -50%);
}

.cursor-dot-clicked {
  width: 50px;
  height: 50px;
  opacity: 0.5;
}

.cursor-outline {
  position: fixed;
  pointer-events: none;
  width: 50px;
  height: 50px;
  border: 1px solid #000000;
  border-radius: 50%;
  transition: transform 0.15s ease-out;
  z-index: 9998;
  transform: translate(-50%, -50%);
}

a:hover ~ .custom-cursor .cursor-outline,
button:hover ~ .custom-cursor .cursor-outline {
  width: 40px;
  height: 40px;
  background-color: rgba(70, 74, 248, 0.1);
}

.custom-folder-shape {
  clip-path: path(
    "M10 10 H 230 A 20 20 0 0 1 250 30 V 60 H 400 A 20 20 0 0 1 420 80 V 300 A 20 20 0 0 1 400 320 H 20 A 20 20 0 0 1 0 300 V 30 A 20 20 0 0 1 20 10 Z"
  );
  border: 2px solid #000;
  border-radius: 12px; /* For smooth edges */
}

.clip-path-none {
  clip-path: none;
}

#home-testimonials .slick-arrow {
  display: block !important;
  position: absolute;
}

#home-testimonials .slick-prev {
  background: url("/src/assets/HomeIcons/ArrowLeft.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  height: 45px !important;
  width: 45px !important;
  z-index: 10 !important;
  top: -80px !important;
  right: 85px !important;
  left: auto !important;
  scale: 1.3 !important;
  /* transform: translate(-170%, -50%) !important; */
}

#home-testimonials .slick-next {
  background: url("/src/assets/HomeIcons/ArrowRight.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  height: 45px !important;
  width: 45px !important;
  z-index: 10 !important;
  top: -80px !important;
  right: 25px !important;
  scale: 1.3 !important;
  /* transform: translate(-170%, -50%) !important; */
}

#home-testimonials .slick-arrow:before {
  content: none !important;
}

#home-testimonials-sm .slick-track {
  display: flex !important;
  gap: 20px !important;
}

#home-testimonials .slick-track {
  display: flex !important;
  gap: 50px !important;
}

#home-testimonials-sm .slick-slide {
  display: flex !important;
  justify-content: center !important;
}

@keyframes swipeLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes swipeRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.swipe-left {
  animation: swipeLeft 0.3s ease-out;
}

.swipe-right {
  animation: swipeRight 0.3s ease-out;
}
